import React from 'react'
import { Image, Text, Title, Box } from '@mantine/core'
import Loading from '../Loading/Loading'
import Banregio from "../../assets/banks/banregio.webp";
import Azteca from "../../assets/banks/azteca.webp";
import Bbva from "../../assets/banks/bbva2.png";
import Hsbc from "../../assets/banks/hsbc.png";
import MercadoPago from "../../assets/banks/mercado-pago.webp";
import Santander from "../../assets/banks/santander-logo-1.webp";
import Efectivo from "../../assets/banks/efectivo.png";
import Spin from "../../assets/banks/spin.png";

const BoxBank = ({ title, value, color }) =>
{
  const bankImages = {
    "mercado pago": MercadoPago,
    bbva: Bbva,
    santander: Santander,
    hsbc: Hsbc,
    banregio: Banregio,
    azteca: Azteca,
    efectivo: Efectivo,
    spin: Spin,
  };

  const renderBankImage = (bankName) => (
    <Box className="w-16 h-2 mb-3">
      <Image
        src={ bankImages[ bankName ] }
        alt={ bankName }
        title={ bankName }
        className="w-auto h-auto"
      />

    </Box>
  );

  return (
    <Box shadow="sm" padding="xl" radius="md" className='border rounded-lg w-full lg:w-64'>
      <Box className='px-4 py-2  flex flex-col gap-2'>
        { renderBankImage(title) }
        { value ?
          <Text class="text-base sm:text-lg md:text-xl lg:text-2xl overflow-hidden text-ellipsis whitespace-nowrap self-end">{ value }</Text>
          :
          <Loading /> }
      </Box>
    </Box>
  )
}

export default BoxBank
