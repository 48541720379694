import React, { useState } from 'react'

import { Table, Tooltip, Text, Box, ActionIcon, Flex, Menu, Button } from '@mantine/core';
import { HiOutlineTrash, HiOutlinePencilAlt, HiDotsVertical, HiReceiptTax } from "react-icons/hi";
import { MdOutlineAccountCircle, MdOutlineMail, MdOutlinePhone, MdOutlinePhoneIphone, MdKeyboardArrowUp, MdKeyboardArrowDown, MdMonetizationOn } from "react-icons/md";
import dayjs from 'dayjs'
import 'dayjs/locale/es'

const CustomerRow = ({ item, onOpen, openConfirm, openRecordModal, _openManualRenovation }) =>
{
  const [ show, setShow ] = useState(false)
  return (
    <>
      <Table.Tr className=' py-4 px-5 '
        key={ item._id }
      >
        <Table.Td px="md" py="lg">
          { item.num_account }
        </Table.Td>
        <Table.Td align="left">{ item?.full_name ? item.full_name : 'N/A' }</Table.Td>

        <Table.Td>
          { dayjs(item?.date_billing).locale('es').format('DD/MMM/YYYY').toUpperCase() }
        </Table.Td>
        <Table.Td>
          { dayjs(item?.limit_date_billing).locale('es').format('DD/MMM/YYYY').toUpperCase() }
        </Table.Td>
        <Table.Td className='relative'>

          <Flex justify="flex-end" align="center" gap="xs">

            <Tooltip label='Ver Cliente' fontSize='md'>
              <ActionIcon onClick={ () => setShow(!show) } variant='light' color="gray.6" size="lg" aria-label='Ver cliente' >{ show ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> }</ActionIcon>
            </Tooltip>
            <Menu shadow="md" width={ 200 } >
              <Menu.Target>
                <ActionIcon aria-label='Ver acciones' variant='light' color="gray.6"  size="lg"><HiDotsVertical/></ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item onClick={ () => onOpen(item, true) } leftSection={<HiOutlinePencilAlt />} aria-label='Editar cliente'>
                 Editar cliente
                </Menu.Item>

                <Menu.Item onClick={ () => openRecordModal(item.num_account) } leftSection={<HiReceiptTax />} aria-label='Ver historial de pago '>
                 Ver historial de pago
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item color="blue" onClick={ () => _openManualRenovation(item) } leftSection={<MdMonetizationOn />} aria-label='Nueva renovación'>
                 Nueva renovación
                </Menu.Item>

                <Menu.Divider />
                <Menu.Item
                  color="red"
                  onClick={ () => openConfirm(item) } aria-label='Borrar cliente'
                  leftSection={<HiOutlineTrash />}
                >
                  Borrar cliente
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>


        </Table.Td>
      </Table.Tr>
      { show && <Table.Tr>
        <Table.Td colSpan={ 5 } px="md" py="lg">
          <Box className='grid grid-cols-2 gap-2 mb-2'>
            <Text className='mt-0 pt-0 text-sm font-semibold'>Información de la cuenta</Text>
            <Text className='mt-0 pt-0 text-sm font-light text-right'>Cliente desde: { dayjs(item?.start_from).locale('es').format('DD/MMM/YYYY').toUpperCase() }</Text>
          </Box>
          <Box>

            <Box className='flex flex-row gap-1 justify-between'>
              <Box className='grid grid-cols-2'>
                <div className='flex flex-row items-center  '><MdOutlineAccountCircle className='mr-1' />{ item?.full_name ? item.full_name : 'N/A' }</div>
                <div className='flex flex-row items-center  '><MdOutlineMail className='mr-1' /> { item?.email ? item.email : 'N/A' }</div>
                <div className='flex flex-row items-center  '> <MdOutlinePhone className='mr-1' />{ item?.phone ? item.phone : 'N/A' }</div>
                <div className='flex flex-row items-center  '> <MdOutlinePhoneIphone className='mr-1' />{ item?.cellphone ? item.cellphone : 'N/A' }</div>
              </Box>
              <Box>
                <div className=' '>Tipo: { item?.type_contract }</div>
                <div className=' '>Plan: { item?.name_type_plan }</div>
                <div className=' '>Días Limite: { item?.limit_payday }</div>
              </Box>
            </Box>
          </Box>
        </Table.Td>
      </Table.Tr> }
    </>
  )
}

export default CustomerRow
